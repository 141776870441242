body {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Open Sans";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

test {
  background-color: aqua;
}

.pLabel {
  font-family: "Open Sans";
  font-size: 12px;
  margin-bottom: 4px;
  color: #757575;
}

.detailContent {
  font-family: "Open Sans";
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 24px;
}

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.form-field {
  margin-bottom: 20px;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  /* border: 1px solid #ccc; */
  font-size: 16px;
  box-sizing: border-box;

  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}
.form-control {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z"/></svg>')
    no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  padding-right: 30px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.appHeader {
  background-color: white !important;
  color: #202020 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #dfdfdf;
}

h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #1d1d1d;
}
.upload_error * {
  color: "#C73333";
}
